/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~ngx-sharebuttons/themes/default';


@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;500;600;700;800&display=swap');



    * {
      font-family: 'Open Sans', sans-serif;
    }
  
    body {
      background: #1e4341;
    }
  
    ion-app {
      max-width: 860px;
      margin: auto;
    }
  
    :root {
      --gradient: linear-gradient(90deg, rgba(255, 125, 33, 1) 0%, rgba(255, 56, 112, 1) 46%, rgba(215, 53, 144, 1) 100%);
      --bg-purple: #c65ae0;
      --laranja: #ff801d;
    }
  
    .footer-background {
      backdrop-filter: none !important;
      -webkit-backdrop-filter: none !important;
    }
  
    /* @media screen and (min-width: 768px){
        ion-menu{
            //display: none;
            width: 260px;
        }
        ion-app{
            margin: auto;
            max-width: 800px;
        }
        ion-content,
        ion-header{
            max-width:570px;
        }
        div{
            max-width: 570px;
        }
        .ion-overlay-wrapper{
            width: 100vw;
            height: 100%;
        }
  
        cadastro-component,
        login-component{
          left: 15% !important;
        }
    } */
  
    ion-toolbar {
      --background: #0000005e linear-gradient(91deg, #5ea15675 0%, #2e6a6773 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      border-radius: 0 0 18px 18px;
      height: 5.2rem;
      position: fixed;
    }
  
    html,
    body {
      scroll-behavior: smooth;
    }
  
    .backdrop-no-scroll {
      background: #1e4341;
    }
  
    input {
      background: #fff;
    }
  
    * {
      font-family: 'Open Sans', sans-serif;
    }
  
    ion-menu-button {
      margin: 20px;
      font-size: 3.2rem;
      color: #fff;
    }
  
    .toolbar-container,
    .toolbar-background {
      background: #fff !important;
    }
  
  
    .btn-gradient-green {
      background: transparent linear-gradient(180deg, #86DA61 0%, #47A29E 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      border-radius: 12px;
      width: 10rem;
      height: 3rem;
    }
  
    .btn-transparent {
      background: transparent;
      border: 2px solid #fff;
      color: #fff;
      border-radius: 12px;
      width: 10rem;
      height: 3rem;
    }
  
    .red {
      color: #ff0000;
    }
  
    .yellow {
      color: #ffcc00;
    }
  
    .thin {
      font-weight: 300;
    }
  
    .semi-bold {
      font-weight: 600;
    }
  
    .bold {
      font-weight: 700 !important;
    }
  
    .extra-bold {
      font-weight: 800;
    }
  
    .flex {
      display: flex;
    }
  
    .centralize {
      justify-content: center;
      align-items: center;
    }
  
    .justify-between {
      justify-content: space-between;
    }
  
    .justify-evenly {
      justify-content: space-evenly;
    }
  
    .justify-around {
      justify-content: space-around;
    }
  
    .column {
      flex-direction: column;
    }
  
    .uppercase {
      text-transform: uppercase !important;
    }
  
    .small-font {
      font-size: 0.9rem;
    }
  
    .small2-font {
      font-size: 0.8rem;
    }
  
    .extra-small-font {
      font-size: 0.55rem !important;
    }
  
    .nowrap {
      white-space: nowrap;
    }
  
    .medium-font {
      font-size: 1.2rem !important;
    }
  
    .large-font {
      font-size: 1.6rem;
    }
  
    .text-center {
      text-align: center;
    }
  
    .text-left {
      text-align: left !important;
    }
  
    .text-right {
      text-align: right;
    }
  
  
    .w-full {
      width: 100% !important;
    }
  
    .w-90 {
      width: 90% !important;
    }
  
    .w-80 {
      width: 80% !important;
    }
  
    .w-75 {
      width: 75% !important;
    }
  
    .w-70 {
      width: 70% !important;
    }
  
    .w-65 {
      width: 65% !important;
    }
  
    .w-60 {
      width: 60% !important;
    }
  
    .w-55 {
      width: 55% !important;
    }
  
    .w-50 {
      width: 50% !important;
    }
  
    .w-45 {
      width: 45% !important;
    }
  
    .w-40 {
      width: 40% !important;
    }
  
    .w-30 {
      width: 30% !important;
    }
  
    .w-20 {
      width: 20% !important;
    }
  
    .w-10 {
      width: 10% !important;
    }
  
    .w-auto {
      width: auto !important;
    }
  
    .mw-2 {
      max-width: 2rem;
    }
  
    .mw-3 {
      max-width: 3rem;
    }
  
    .mw-4 {
      max-width: 4rem;
    }
  
    .mw-100rem {
      max-width: 100rem !important;
    }
  
    .mh-1rem {
      max-height: 1rem !important;
    }
  
    .mh-1-5rem {
      max-height: 1.5rem !important;
    }
  
    .mh-2rem {
      max-height: 2rem !important;
    }
  
    .mh-3rem {
      max-height: 3rem !important;
    }
  
    .mh-4rem {
      max-height: 4rem !important;
    }
  
  
    .white {
      color: #fff !important;
    }
  
    .grey {
      color: #999 !important;
    }
  
    .mt-4rem {
      margin-top: 4rem !important;
    }
  
    .mt-6rem {
      margin-top: 6rem !important;
    }
  
    .mb-1 {
      margin-bottom: 1rem;
    }
  
    .mb-2 {
      margin-bottom: 2rem;
    }
  
    .mb-3 {
      margin-bottom: 3rem;
    }
  
    .mb-4 {
      margin-bottom: 4rem;
    }
  
    .mb-5 {
      margin-bottom: 5rem;
    }
  
    .mb-6 {
      margin-bottom: 6rem;
    }
  
    .mb-7 {
      margin-bottom: 7rem;
    }
  
    .mb-8 {
      margin-bottom: 8rem;
    }
  
    .mb-9 {
      margin-bottom: 9rem;
    }
  
    .mb-10 {
      margin-bottom: 10rem;
    }
  
    .ml-2 {
      margin-left: 2rem !important;
    }
  
    .ml-3 {
      margin-left: 3rem !important;
    }
  
    .ml-4 {
      margin-left: 4rem !important;
    }
  
    .ml-5 {
      margin-left: 5rem !important;
    }
  
    .minus-mt-2 {
      margin-top: -2rem !important;
    }
  
    .minus-mt-5 {
      margin-top: -5rem !important;
    }
  
    .minus-mt-7 {
      margin-top: -7rem !important;
    }
  
    .minus-mt-10 {
      margin-top: -10rem !important;
    }
  
    .br-1 {
      border-radius: 0.55rem !important;
    }
  
    .br-2 {
      border-radius: 1rem !important;
    }
  
    .br-t-1 {
      border-top-left-radius: 0.55rem !important;
      border-top-right-radius: 0.55rem !important;
    }
  
    .br-t-2 {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
  
    .b-r-grey {
      border-right: 1.7px solid #999;
    }
  
    .b-r-white {
      border-right: 1.7px solid #fff;
    }
  
    .b-l-white {
      border-left: 2.5px solid #fff;
    }
  
    .b-l-grey {
      border-left: 2.5px solid #999;
    }
  
  
    .bg-white {
      background: #fff;
    }
  
    .bg-blue {
      background-color: #2356b0;
    }
  
    .bg-red {
      background-color: #c0001f;
    }
  
  .slides-grey{
    .swiper-pagination-bullet-active{
      background-color: #296360;
    }
  }
  
    #primeiro_acesso{
        margin-top: 25px;
  
      .swiper-pagination-bullet {
        width: 20px !important;
        height: 20px !important;
        margin: 0 14px;
        background: #fff;
        border: 1px solid #64858f;
      }
  
      .swiper-pagination-bullet-active {
        background: #8cc0ce;
      }
  
    }
  
    .slides_card {
      margin-top: 40px;
      margin-bottom: 100px;
      overflow: unset !important;
  
      .swiper-pagination {
        bottom: -45px;
  
        .swiper-pagination-bullet {
          background: #eee;
          width: 38px;
          height: 38px;
          border: 1px solid gray;
        }
  
        .swiper-pagination-bullet:active {
          background-color: #296360 !important;
        }
      }
  
    }
  
    .slides_produtos {
      padding: 20px 0;
  
      h4 {
        font-weight: bold;
      }
  
      .swiper-pagination {
        bottom: 10px;
  
        .swiper-pagination-bullet {
          width: 18px !important;
          height: 18px !important;
          background: #fff;
          opacity: .8;
        }
  
        .swiper-pagination-bullet-active {
          background: #9c2ee1;
        }
      }
    }
  
    .slides_parceiros {
      padding: 20px 0;
  
      h4 {
        font-weight: bold;
      }
  
      .swiper-pagination {
        bottom: 10px;
  
        .swiper-pagination-bullet {
          width: 18px !important;
          height: 18px !important;
          background: #333;
          opacity: .8;
        }
  
        .swiper-pagination-bullet-active {
          background: #296360;
        }
      }
    }
  
    .btn-primary {
      background: linear-gradient(#86DA61, #47A29E) !important;
      background-color: linear-gradient(#86DA61, #47A29E) !important;
      border: 1px solid linear-gradient(#86DA61, #47A29E) !important;
      color: #fff !important;
      padding: 2px 10px;
      border-radius: 20px;
      white-space: nowrap;
    }
  
    .btn-secondary {
      background: #fdbe33 !important;
      background-color: #fdbe33 !important;
      border: 1px solid #f6b832 !important;
      color: #fff !important;
      padding: 2px 10px;
      border-radius: 20px;
      white-space: nowrap;
    }
  
    .btn-warning {
      background: #f46c2f !important;
      background-color: #f46c2f !important;
      border: 1px solid #f46c2f !important;
      color: #fff !important;
      padding: 2px 10px;
      border-radius: 20px;
      white-space: nowrap;
    }
  
    .btn-tertiary {
      background: #1049ab !important;
      background-color: #1049ab !important;
      border: 1px solid #1049ab !important;
      color: #fff !important;
      padding: 2px 10px;
      border-radius: 20px;
      white-space: nowrap;
    }
  
    .btn-round-primary {
      width: 44px;
      font-size: 18pt;
      font-weight: bold;
      background: linear-gradient(#86DA61, #47A29E) !important;
      background-color: #47A29E !important;
      border: 1px solid #47A29E !important;
      color: #fff !important;
      padding: 2px 15px;
      border-radius: 50%;
    }
  
    .btn-round-secondary {
      width: 44px;
      font-size: 18pt;
      font-weight: bold;
      background: #fdbe33 !important;
      background-color: #fdbe33 !important;
      border: 1px solid #f6b832 !important;
      color: #fff !important;
      padding: 2px 15px;
      border-radius: 50%;
    }
  
    .btn-round-tertiary {
      width: 44px;
      font-size: 18pt;
      font-weight: bold;
      background: #1049ab !important;
      background-color: #1049ab !important;
      border: 1px solid #1049ab !important;
      color: #fff !important;
      padding: 2px 15px;
      border-radius: 50%;
    }
  
    .btn_pontos.orange {
      background-color: var(--laranja);
    }
  
    .btn_pontos.purple {
      background-color: var(--bg-purple);
    }
  
    .btn_pontos {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16rem;
      height: auto;
      color: #fff;
      padding: 0.1rem;
      border-radius: 9px;
      text-transform: uppercase;
      font-size: .8rem;
      text-align: center;
  
      #left {
        padding-right: 0.5rem;
        border-right: 1px solid #fff;
        font-weight: 700;
      }
  
      #right {
        padding-left: 0.5rem;
        font-size: 0.7rem;
        font-weight: 400;
        text-align: center;
        padding-bottom: 5px;
  
        strong {
          font-size: 1.6rem;
  
        }
      }
    }
  
  
    .wrp-inc-perfil {
      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -6rem;
      margin-bottom: 5rem;
  
      .btn-ganhar-mais {
        cursor: pointer;
        width: 80%;
        height: 3rem;
        font-size: .8rem;
        color: #fff;
        background-color: #fcbd33;
        text-transform: uppercase;
        text-align: center;
        border-radius: 9px;
        font-weight: 700;
        white-space: nowrap;
      }
    }
  
  
  
    .check-radio {
      position: relative;
    }
  
    .check-radio input {
      -webkit-appearance: none;
      appearance: none;
      background: linear-gradient(#dd0606, #696969);
      ;
      width: 40px;
      height: 15px;
      border-radius: 10px;
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, .5);
    }
  
    .check-radio input:checked {
      background: linear-gradient(#86DA61, #47A29E);
    }
  
    .check-radio input:focus {
      outline: none;
      cursor: pointer;
    }
  
    .check-radio input:before {
      content: '';
      margin-top: -2.5px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: rgb(229, 229, 229);
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px #b71c1c, 0 2px 5px rgba(0, 0, 0, .5);
      position: absolute;
      left: 0;
      transition: left .3s ease-out;
      -webkit-transition: left .3s ease-out;
    }
  
    .check-radio input:after {
      //content: 'FF';
      display: block;
      margin-top: -2.5px;
      left: 20px;
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      transition: left .3s ease-out;
      -webkit-transition: left .3s ease-out;
      color: #838383;
    }
  
    .check-radio input {
      background-color: pink;
    }
  
    .check-radio input:checked {
      background-color: lime;
    }
  
    .check-radio input:before {
      box-shadow: inset 0 0 0 2px rgb(204, 15, 15), 0 2px 5px rgba(0, 0, 0, .5);
    }
  
    .check-radio input:checked:before {
      left: 22.5px;
      box-shadow: inset 0 0 0 2px green, 0 2px 5px rgba(0, 0, 0, .5);
    }
  
    .check-radio input:checked:after {
      //content: 'N';
      left: 43.5px;
      color: green;
    }
  
  
    .margin-negative {
      margin-top: -1rem;
    }
  

